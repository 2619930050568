@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');


@layer components {
  .Icon{
    @apply h-6 w-6 
  }
}

 

body {
  margin: 0;
  background-color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@keyframes slide-up {
     
  /* 0% {
    transform: translateY(100px)

  }
 
  100%{
    transform: translateY(0)
    
  } */

  from {
    opacity: 0.2;
    line-height: 40px;
  }
  to {
    opacity: 1;
    line-height: 25px;
  }
}

 
@keyframes fadeInUp {
0% {
  opacity: 0;
  transform: translateY(-20px) scale(0.9);
}
100% {
  opacity: 1;
  transform: translateY(0) scale(1);
}
}

.disappear > * {
opacity: 0;
animation: fadeInUp 1s ease-in-out forwards ;
}

.disappear > *:nth-child(1) {
animation-delay: 0.5s;
}

.disappear > *:nth-child(2) {
animation-delay: 1s;
}



.disappear > *:nth-child(3) {
animation-delay: 1.5s;
}

.disappear > *:nth-child(4) {
animation-delay: 2s;
}

.disappear > *:nth-child(5) {
animation-delay: 2.5s;
}

#reflected-text {
color: #333; /* Text color */
text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb,
             0 4px 0 #b9b9b9, 0 5px 0 #aaa, 0 6px 1px rgba(0,0,0,.1),
             0 0 5px rgba(0,0,0,.1), 0 1px 3px rgba(0,0,0,.3),
             0 3px 5px rgba(0,0,0,.2), 0 5px 10px rgba(0,0,0,.25),
             0 10px 10px rgba(0,0,0,.2), 0 20px 20px rgba(0,0,0,.15);
}

.text-gradient {
background: linear-gradient(90deg, #f6ad55, #f9d4a1);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}



 

.mainy{
  height: 60vh;
  width:100%;  
  display:flex;
  align-items:center;
  justify-content:center;
  text-align:center;
}
.mainy h1{
  text-align:center;
  text-transform: uppercase;
  color: #F1FAEE; 
  font-size: 2.125rem;
}

 

.roller{
  height: 4.125rem;
  line-height: 4rem;
  position: relative;
  overflow: hidden; 
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}


#spare-time{
  font-size: 1rem;
  font-style: italic;
  letter-spacing: 1rem;
  margin-top: 0;
  color: #A8DADC;
  
}

.roller #rolltext {
  position: absolute;
  top: 0;
  animation: slide 10s infinite;  
}

@keyframes slide {
  0%{
    top:0;
  }
  25%{
    top: -4rem;    
  }
  50%{
    top: -8rem;
  }
  72.5%{
    top: -12.25rem;
  }
}

@media screen and (max-width: 600px){
   
  
  .roller{
  height: 2.6rem; 
  line-height: 2.125rem;  
  }
  
  #spare-time {
    font-size: 1rem;
    letter-spacing: 0.1rem;
  }
  
  .roller #rolltext {  
  animation: slide-mob 5s infinite;  
}
  
  @keyframes slide-mob {
  0%{
    top:0;
  }
  25%{
    top: -2.125rem;    
  }
  50%{
    top: -4.25rem;
  }
  72.5%{
    top: -6.375rem;
  }
}
}



  .dynamic{
   
    animation: slide-up 0.9s   cubic-bezier(0.65, 0, 0.35, 1)   both
    
  }
 
 
 

 




